<template>
  <v-row class="prices">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="white--text text-uppercase text-sm-h5 text-h6 font-weight-bold">Prices</div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by name, operator, MCC or MNC"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
          <v-btn @click="newModal = !newModal" elevation="0" class="white--text ml-5" width="184" height="48" color="primary">
            Add Prices
          </v-btn>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <div class="accent select-block">
          <v-autocomplete
            :items="selectItems"
            v-model="account"
            prepend-inner-icon="mdi-magnify"
            hide-details
            item-text="name"
            item-value="id"
            filled
            outlined
            dense
            color="input"
            @change="getData"
            height="40"
          >
          </v-autocomplete>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card flat height="70vh" min-height="260" v-if="!data.result" class="pa-3 d-flex align-center justify-center flex-column">
            <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
            <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
          </v-card>
          <div v-else>
            <v-data-table
              hide-default-footer
              fixed-header
              :headers="headers"
              :items="data.result"
              item-key="name"
              :items-per-page="Number(itemsPerPage)"
              :height="`calc(100vh - 292px)`"
              class="d-sm-block d-none p-table"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id" height="52">
                    <td>{{ item.mcc }}/{{ item.mnc }}</td>
                    <td>{{ item.country }} - {{ item.operator }}</td>
                    <td>{{ item.type.title }}</td>
                    <td>
                      <div
                        class="f18 font-weight-medium"
                        :class="{ 'price-item': item.type.name == 'account' }"
                      >
                        {{ item.price }}
                      </div>
                    </td>
                    <td>{{ item.provider.name }}</td>
                    <td>
                      <div class="mx-auto status" :class="item.enabled ? 'success' : 'error'">
                        {{ item.enabled ? 'On' : 'Off' }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-center justify-center">
                        <v-btn
                          v-if="item.actions.find((item) => item == 'add')"
                          class="rounded"
                          text
                          icon
                          tile
                          elevation="0"
                          @click="openEdit(item, 'new')"
                        >
                          <img width="24" height="24" src="@/assets/icon/plus.svg" alt="plus" />
                        </v-btn>
                        <v-btn
                          v-if="item.actions.find((item) => item == 'edit')"
                          class="rounded"
                          text
                          icon
                          tile
                          elevation="0"
                          @click="openEdit(item, 'edit')"
                        >
                          <img width="24" height="24" src="@/assets/icon/pencil.svg" alt="edit" />
                        </v-btn>
                        <span v-if="item.actions.length > 1" class="divider-vertical mx-3"></span>
                        <v-btn
                          v-if="item.actions.find((item) => item == 'delete')"
                          class="rounded"
                          color="error"
                          text
                          icon
                          tile
                          elevation="0"
                          @click="openDelete(item)"
                        >
                          <img width="24" height="24" src="@/assets/icon/backet.svg" alt="delete" />
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                  <span class="grey--text">Rows per page:</span>
                  <div class="perPageSelect">
                    <v-select v-model="itemsPerPage" :items="itemsPerPageArray" hide-details dense @change="getData"></v-select>
                  </div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-data-table>
            <div class="d-block d-sm-none">
              <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">MCC/MNC</div>
                  <div class="font-weight-medium f18">{{ item.mcc }}/{{ item.mnc }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Country/Operator</div>
                  <div class="font-weight-medium f18">{{ item.country }} - {{ item.operator }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Type</div>
                  <div class="font-weight-medium f18">{{ item.type.title }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Price</div>
                  <div
                    class="f18 font-weight-medium"
                    :class="{ 'price-item': item.type.name == 'account' }"
                  >
                    {{ item.price }}
                  </div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Provider</div>
                  <div class="font-weight-medium f18">{{ item.provider.name }}</div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Enabled</div>
                  <div class="font-weight-medium f18">
                    <div class="mx-auto status" :class="item.enabled ? 'success' : 'error'">
                      {{ item.enabled ? 'On' : 'Off' }}
                    </div>
                  </div>
                </div>
                <div class="d-flex my-1">
                  <div class="opasity--text f18 font-weight-medium label-table">Actions</div>
                  <div class="font-weight-medium f18">
                    <div class="d-flex align-center">
                      <v-btn
                        v-if="item.actions.find((item) => item == 'add')"
                        class="rounded"
                        text
                        icon
                        tile
                        elevation="0"
                        @click="openEdit(item, 'new')"
                      >
                        <img width="24" height="24" src="@/assets/icon/plus.svg" alt="plus" />
                      </v-btn>
                      <v-btn
                        v-if="item.actions.find((item) => item == 'edit')"
                        class="rounded"
                        text
                        icon
                        tile
                        elevation="0"
                        @click="openEdit(item, 'edit')"
                      >
                        <img width="24" height="24" src="@/assets/icon/pencil.svg" alt="edit" />
                      </v-btn>
                      <span v-if="item.actions.length > 1" class="divider-vertical mx-3"></span>
                      <v-btn
                        v-if="item.actions.find((item) => item == 'delete')"
                        class="rounded"
                        color="error"
                        text
                        icon
                        tile
                        elevation="0"
                        @click="openDelete(item)"
                      >
                        <img width="24" height="24" src="@/assets/icon/backet.svg" alt="delete" />
                      </v-btn>
                    </div>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <v-row class="my-2 pb-2" align="center" justify="center">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-3 mt-1 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
      <v-dialog v-model="newModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add Price</div>
          <v-row>
            <v-col cols="12" class="pa-0 px-3">
              <div class="text-h6 mb-2">General account</div>
            </v-col>
            <v-col cols="6" class="pa-0 pr-md-2 px-3">
              <v-text-field
                v-model="newPrice.mcc"
                label="MCC"
                :error-messages="mccError"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pl-md-2 px-3">
              <v-text-field
                v-model="newPrice.mnc"
                label="MNC"
                :error-messages="mncError"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pr-md-2 px-3">
              <v-text-field
                v-model="newPrice.price"
                label="Price"
                :error-messages="priceError"
                filled
                outlined
                dense
                required
                color="input"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pl-md-2 px-3">
              <v-select
                v-model="newPrice.currency"
                :items="currencyList"
                label="Currency"
                item-text="title"
                item-value="name"
                :error-messages="currencyError"
                filled
                outlined
                dense
                required
                color="input"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pa-0 px-3">
              <v-select
                v-model="newPrice.provider"
                :items="provider"
                label="Provider"
                item-text="name"
                item-value="id"
                :error-messages="providerError"
                filled
                outlined
                dense
                required
                color="input"
              ></v-select>
            </v-col>
          </v-row>
          <v-checkbox v-model="newPrice.enabled" color="success" hide-details>
            <template v-slot:label>
              <div class="text-h6 mt-n1">Enabled</div>
            </template>
          </v-checkbox>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="addPrice">Add</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="newModal = !newModal">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="newAccModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Add Account Price</div>
          <v-row>
            <v-col cols="12" class="pa-0 px-3">
              <v-select
                v-model="account"
                :items="selectItems"
                label="Account"
                item-text="name"
                item-value="id"
                filled
                outlined
                dense
                disabled
              ></v-select>
            </v-col>
            <v-col cols="6" class="pa-0 pr-md-2 px-3">
              <v-text-field v-model="editedItem.mcc" label="MCC" filled outlined dense disabled></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pl-md-2 px-3">
              <v-text-field v-model="editedItem.mnc" label="MNC" filled outlined dense disabled></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pr-md-2 px-3">
              <v-text-field v-model="editedItem.cleanPrice" label="Price" filled outlined dense color="input"></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pl-md-2 px-3">
              <v-select
                v-model="editedItem.currency"
                :items="currencyList"
                label="Currency"
                item-text="title"
                item-value="name"
                filled
                outlined
                dense
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" class="pa-0 px-3">
              <v-select
                v-model="editedItem.provider"
                :items="provider"
                label="Provider"
                item-text="name"
                item-value="id"
                filled
                outlined
                dense
                required
                color="input"
              ></v-select>
            </v-col>
          </v-row>
          <v-checkbox v-model="editedItem.enabled" color="success" hide-details>
            <template v-slot:label>
              <div class="text-h6 mt-n1">Enabled</div>
            </template>
          </v-checkbox>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="addPriceAcc">Add</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="closeDialog">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4">
          <div class="text-h5 font-weight-bold mb-6">Edit Price</div>
          <v-row>
            <v-col cols="12" class="pa-0 px-3">
              <div class="text-h6 mb-2">General account</div>
            </v-col>
            <v-col cols="6" class="pa-0 pr-md-2 px-3">
              <v-text-field v-model="editedItem.mcc" label="MCC" filled outlined dense disabled></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pl-md-2 px-3">
              <v-text-field v-model="editedItem.mnc" label="MNC" filled outlined dense disabled></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pr-md-2 px-3">
              <v-text-field v-model="editedItem.cleanPrice" label="Price" filled outlined dense color="input"></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-0 pl-md-2 px-3">
              <v-select
                v-model="editedItem.currency"
                :items="currencyList"
                label="Currency"
                item-text="title"
                item-value="name"
                filled
                outlined
                dense
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" class="pa-0 px-3">
              <v-select
                v-model="editedItem.provider"
                :items="provider"
                label="Provider"
                item-text="name"
                item-value="id"
                filled
                outlined
                dense
                required
                color="input"
              ></v-select>
            </v-col>
          </v-row>
          <v-checkbox v-model="editedItem.enabled" color="success" hide-details>
            <template v-slot:label>
              <div class="text-h6 mt-n1">Enabled</div>
            </template>
          </v-checkbox>
          <div class="mt-7 d-flex flex-column align-center">
            <v-btn width="184" height="48" color="primary" @click="updatePrice">Update</v-btn>
            <v-btn width="184" height="48" text color="secondary" class="mt-3" @click="closeDialog">Close</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Remove the price</div>
          <div class="mt-3 opasity--text">
            Do you really want to remove the price for MCC {{ editedItem.mcc }} and MNC {{ editedItem.mnc }}
          </div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="closeDialog">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="remove">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    newPrice: {
      mcc: { required },
      mnc: { required },
      price: { required },
      currency: { required },
      provider: { required },
    },
  },
  data() {
    return {
      load: true,
      newModal: false,
      newAccModal: false,
      editModal: false,
      deleteModal: false,
      editedItem: {},
      newPrice: {},
      newPriceAcc: {},
      search: this.$route.query.src || '',
      itemsPerPage: +this.$route.query.page_size || 10,
      page: Number(this.$route.query.page) || 1,
      account: this.$route.query.account || 'general',
      selectItems: [{ id: 'general', name: '<General>' }],
      headers: [
        { text: 'MCC/MNC', value: 'mcc', sortable: false },
        { text: 'Country/Operator', value: 'country', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Price', value: 'price', sortable: false },
        { text: 'Provider', value: 'provider', sortable: false },
        { text: 'Enabled', value: 'enabled', sortable: false },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false },
      ],
      statusError: null,
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getAccountsAll');
    this.$store.dispatch('getProviderAll');
    this.$store.dispatch('getCurrency');
  },
  methods: {
    notifi(btn) {
      if (btn == 'created') {
        this.$notify({ message: 'Prices has been created', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'update') {
        this.$notify({ message: 'Prices has been upadate', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'action__not_allowed')) {
        this.$notify({
          message: 'Staff is not allowed to create Price',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'price__already_exist')) {
        this.$notify({
          message: 'Price item with provided mcc, mnc, account and currency already exist',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'general_price__not_exist')) {
        this.$notify({
          message: 'General price item with provided mcc, mnc and currency does not exist',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    openEdit(item, btn) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.currency = this.currencyList.find((item) => item.title == this.editedItem.price.substr(-1));
      this.editedItem.cleanPrice = this.editedItem.price.replace(/[^0-9,.]/g, '');
      this.editedItem.provider = this.editedItem.provider.id;
      btn == 'new' ? (this.newAccModal = true) : (this.editModal = true);
    },
    openDelete(item) {
      this.editedItem = Object.assign({}, item);
      this.deleteModal = true;
    },
    closeDialog() {
      this.newAccModal = false;
      this.editModal = false;
      this.deleteModal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getPricesList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
          account: this.account,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setPricesList', {});
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async addPrice() {
      this.error = [];
      this.$v.newPrice.$touch();
      if (!this.$v.newPrice.$invalid) {
        const data = new Object();
        data.mcc = +this.newPrice.mcc;
        data.mnc = +this.newPrice.mnc;
        data.price = this.newPrice.price * 1000;
        data.currency = this.newPrice.currency;
        data.enabled = this.newPrice.enabled;
        data.provider = this.newPrice.provider;
        await this.$store
          .dispatch('createPrices', data)
          .then(() => {
            this.newModal = false;
            this.notifi('created');
            this.getData();
          })
          .catch((e) => {
            this.error = e.response.data.error;
            this.notifi();
          });
      }
    },
    async remove() {
      await this.$store.dispatch('deletePrices', this.editedItem.id).then(() => {
        this.closeDialog();
        this.getData();
      });
    },
    async updatePrice() {
      this.error = [];
      const data = new Object();
      data.price = +this.editedItem.cleanPrice * 1000;
      data.enabled = this.editedItem.enabled;
      data.provider = this.editedItem.provider;
      await this.$store
        .dispatch('updatePrices', {
          id: this.editedItem.id,
          data: data,
        })
        .then(() => {
          this.closeDialog();
          this.notifi('update');
          this.getData();
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.notifi();
        });
    },
    async addPriceAcc() {
      this.error = [];
      const data = new Object();
      data.account = this.account;
      data.mcc = +this.editedItem.mcc;
      data.mnc = +this.editedItem.mnc;
      data.price = this.editedItem.cleanPrice * 1000;
      data.currency = this.editedItem.currency.name;
      data.enabled = this.editedItem.enabled;
      data.provider = this.editedItem.provider;
      await this.$store
        .dispatch('createPrices', data)
        .then(() => {
          this.closeDialog();
          this.notifi('created');
          this.getData();
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.notifi();
        });
    },
  },
  watch: {
    accounts() {
      this.selectItems = this.accounts.slice();
      this.selectItems.unshift({ id: 'general', name: '<General>' });
    },
    newModal() {
      this.$v.newPrice.$reset();
      this.newPrice = {};
    },
    newAccModal(val) {
      val || this.closeDialog();
    },
    editModal(val) {
      val || this.closeDialog();
    },
    deleteModal(val) {
      val || this.closeDialog();
    },
  },
  computed: {
    data() {
      return this.$store.getters.pricesList;
    },
    accounts() {
      return this.$store.getters.accountsAll;
    },
    provider() {
      return this.$store.getters.providerAll;
    },
    currencyList() {
      return this.$store.getters.currency;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
    mccError() {
      const errors = [];
      if (!this.$v.newPrice.mcc.$dirty) {
        return errors;
      }
      !this.$v.newPrice.mcc.required && errors.push('Please enter MCC');
      this.error == 'mcc__invalid' && errors.push('Provided MCC is not valid');
      return errors;
    },
    mncError() {
      const errors = [];
      if (!this.$v.newPrice.mnc.$dirty) {
        return errors;
      }
      !this.$v.newPrice.mnc.required && errors.push('Please enter MNC');
      this.error == 'mnc__invalid' && errors.push('Provided MNC is not valid');
      return errors;
    },
    priceError() {
      const errors = [];
      if (!this.$v.newPrice.price.$dirty) {
        return errors;
      }
      !this.$v.newPrice.price.required && errors.push('Please enter price');
      this.error == 'price__invalid' && errors.push('Provided price is not valid');
      this.error == 'price__already_exist' && errors.push('Provided price already exist');
      return errors;
    },
    currencyError() {
      const errors = [];
      if (!this.$v.newPrice.currency.$dirty) {
        return errors;
      }
      !this.$v.newPrice.currency.required && errors.push('Please enter currency');
      this.error == 'currency__invalid' && errors.push('Provided currency is not valid');
      return errors;
    },
    providerError() {
      const errors = [];
      if (!this.$v.newPrice.provider.$dirty) {
        return errors;
      }
      !this.$v.newPrice.provider.required && errors.push('Please enter provider');
      this.error == 'provider__invalid' && errors.push('Provided provider is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setPricesList', {});
    this.$store.dispatch('setAccountsAll', []);
    this.$store.dispatch('setProviderAll', []);
  },
};
</script>

<style lang="scss">
.prices {
  .price-item {
    text-align: center;
    border-radius: 24px;
    line-height: 26px;
    width: fit-content;
    padding: 0 9px;
    color: white;
    background: var(--v-input-base);
  }
  .status {
    text-align: center;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    width: fit-content;
    padding: 0 16px;
    color: white;
  }
  .divider-vertical {
    height: 12px;
  }
  .select-block {
    padding: 8px 40px;

    .v-select {
      max-width: 300px;
      width: 100%;
    }
    .v-input__slot {
      background: white !important;
    }
    .v-input__append-inner,
    .v-input__prepend-inner {
      margin-top: 10px !important;
    }
  }
  .label-table {
    max-width: 180px;
  }
}
</style>
